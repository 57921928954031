import '../scss/app.scss';
import '../scss/navigation.scss';
import '../scss/footer.scss';

import $ from 'jquery';
import 'jquery.scrollto';
import 'jquery.easing';
import 'slick-carousel';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import './navigation';

global.$ = $;
global.jQuery = $;
window.$ = $;
window.jQuery = $;
