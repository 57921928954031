import $ from 'jquery';

// add class to top navigation on scroll
$(window).scroll(function () {
    const scroll = $(window).scrollTop();
    if (scroll > 50) {
        $('.navbar').addClass('sticky');
    } else {
        $('.navbar').removeClass('sticky');
    }
});

// smooth scroll animation on anchor links
$('a[href^="#"]').click(function smoothScroll(e) {
    e.preventDefault();
    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top,
    }, 500);
});